<template>
  <div class="header" id="main">
    <!-- <div id="main"> -->
    <div class="left" style="width: 1200px;margin: 0 auto;">
      <router-link to="/" tag="div">
        <img
        class="logo" style="cursor: pointer;"
        :src="
          myBusiness && myBusiness.logo != null ? myBusiness.logo : defaulHeader
        "
        alt=""
      />
    </router-link>
      <div class="logo-css">
        <router-link to="/" tag="div">
        <span class="logo_title" style="cursor: pointer;">{{
          myBusiness && myBusiness.name != "" ? myBusiness.name : ""
        }}</span>
        </router-link>
      </div>
      <div class="searchto" style="cursor: pointer;">
        <input class="input no-cursor" @keyup.enter="sousuo" v-model="sousuo_con" type="text" style="height: 45px;width: 388px;background-color: #f2f5f9;border: none;border-radius: 50px;padding-left: 20px;" placeholder="搜索感兴趣的内容">
        <img src="../assets/images/fangdajing.png" class="search-btn" @click="sousuo()">
<!--        <el-row class="demo-autocomplete">-->
<!--          <el-col :span="12">-->
<!--            <el-autocomplete-->
<!--                class="inline-input"-->
<!--                v-model="state1"-->
<!--                :fetch-suggestions="querySearch"-->
<!--                placeholder="搜索感兴趣的内容"-->
<!--                suffix-icon="el-icon-search"-->
<!--                @select="handleSelect"-->
<!--                style="width: 450px"-->
<!--            ></el-autocomplete>-->
<!--          </el-col>-->
<!--        </el-row>-->
      </div>
      <div class="search" >
        <div class="search_but">
          <!-- <p class="search_but_text" @click="globalSearch">搜索</p> -->
          <div class="right">
            <span
              class="please"
              style="display: inline-block; display: flex"
              v-if="!userInfo || JSON.stringify(userInfo) == '{}'"
            >
               <div class="login-css">
                  <span @click="loginMask" style="cursor: pointer">请登录</span>
                  <!-- <el-divider direction="vertical"></el-divider> -->
                  <!-- <span style="cursor: pointer">注册</span> -->
              </div>
            </span>
            <!-- <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
            > -->
             <!-- <div class="mask" v-if="maskShow">
               <div class="register" v-if="registerShow" key="register">
                 <i class="el-icon-close" @click="closeMask('registerForm')"></i>
                 <span>注册</span>
                 <form>
                   <label class="label-name-icon">
                    <input
                         type="text"
                         placeholder="真实姓名"
                          @blur="checkName('name-check')"
                         v-model="resgisterForm.name" />
                      <b class="check-mark" ref="name-check">请输入姓名</b>
                   </label>
                    <label class="label-unit-icon">
                      <el-autocomplete
                          placeholder="单位"
                        :trigger-on-focus="false"
                         :fetch-suggestions="querySearch"
                          value-key="name"
                         v-model="unitName"
                         @select="assignQueryString"
                          @blur="checkCompany('company-check', 'company-right-check')"
                     ></el-autocomplete>
                     <b class="check-mark" ref="company-check">请输入单位名称</b>
                      <b class="check-mark" ref="company-right-check"
                      >请输入正确的公司名称</b
                      >
                    </label>
                   <label class="label-unit-icon">
                     <input
                          type="text"
                          placeholder="请务必输入企业全称"
                         @blur="checkCompany('company-check', 'company-right-check')"
                         v-model="resgisterForm.company"
                     ></input>
                     <b class="check-mark" ref="company-check">请输入企业名称</b>
                    <b class="check-mark" ref="company-right-check"
                      >请输入企业全称</b>
                      >
                    </label>
                    <label class="label-phone-icon">
                     <input
                         type="number"
                          placeholder="手机号"
                         @blur="checkMobile('mobile-check')"
                          @input="
                 if (resgisterForm.mobile.length > 11)
                    resgisterForm.mobile = resgisterForm.mobile.slice(0, 11);
               "
                         v-model="resgisterForm.mobile"
                      />
                     <b class="check-mark" ref="mobile-check">请输入手机号</b>
                   </label>
                    <label class="label-code-icon">
                      <input
                         type="number"
                          placeholder="验证码"
                        @blur="checkCaptcha('captcha-check')"
                         v-model="resgisterForm.code"
                      />
                     <button
                          class="send-code-btn"
                         @click.prevent="sendCode"
                          v-show="countDown === 60"
                     >
                        发送验证码
                     </button>
                     <button
                         class="send-code-btn send-disable"
                         disabled
                         v-show="countDown !== 60"
                      >
                        重新发送( {{ countDown }} )
                     </button>
                     <b class="check-mark" ref="captcha-check">请输入验证码 </b>
                    </label>
                   <label class="label-password-icon">
                      <input
                          type="password"
                         placeholder="密码"
                          maxlength="16"
                          v-model="resgisterForm.password"
                         @blur="checkPwd('password-check')"
                         @input="
                 resgisterForm.password = resgisterForm.password.replace(
                   /[^\w\.\/]/gi,
                   ''
                  )
               "
                     />
                    <b class="check-mark" ref="password-check">请输入密码</b>
                  </label>
                    <label class="label-password-icon">
                    <input
                         type="password"
                          maxlength="16"
                          placeholder="确认密码"
                         v-model="resgisterForm.real_password"
                         @input="
                  resgisterForm.real_password =
                    resgisterForm.real_password.replace(/[^\w\.\/]/gi, '')
              "
                        @blur="checkRePwd('re-password-check')"
                    />
                     <b class="check-mark" ref="re-password-check">请输入确认密码</b>
                  </label>
                    <button class="now-register" @click.prevent="registerReq">
                      立即注册
                   </button>
                 </form>
               </div>
              </div> -->
         <!-- </transition> -->
            <el-dropdown trigger="click" style="cursor: pointer;left: 230px" v-else>
              <span class="el-dropdown-link" style="display: flex">
                <img
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-top: 30px;
                  "
                  :src="
                    userInfo && userInfo.head_img !== null
                      ? userInfo.head_img
                      : defaulHeader
                  "
                  alt=""
                />
                <p class="header_name" style="margin-top: 39px; margin-left: 10px;">
                  {{ userInfo.name }}
                  <i
                    style="margin-top: 3%; margin-left: 55%"
                  ></i>
                </p>
              </span>
              <div class="mymenu">
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="updateInfo"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="logOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="c-b">
      <div class="certificate" ref="certificate">
        <span class="cert-name">{{ certInfo.name }}</span>
        <span class="cert-stime">{{ certInfo.start_time }}</span>
        <span class="cert-etime">{{ certInfo.end_time }}</span>
        <span class="cert-course-time">{{ certInfo.class_num }}</span>
        <span class="cert-identifier">{{ certInfo.cert_num }}</span>
        <span class="cert-ftime">{{ certInfo.end_time }}</span>
        <span class="cert-cname">{{ certInfo.cert_name }}</span>
      </div>
    </div>

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="mask" v-if="maskShow">
        <div class="login-mask" v-if="loginShow" key="login">
          <i class="el-icon-close" @click="closeLoginMask('loginForm')"></i>
          <span>登录</span>
          <el-form
            ref="loginForm"
            label-width="auto"
            :rules="loginRule"
            :model="loginForm"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="loginForm.mobile"
                type="number"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                maxlength="16"
                @input="
                  loginForm.password = loginForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
                v-model="loginForm.password"
                :onkeyup="(loginForm.password = loginForm.password.trim())"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click.prevent="loginMethods('loginForm')"
                >登录</el-button
              >
              <el-button @click="closeLoginMask('loginForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import "animate.css";
import html2canvas from "html2canvas";

import defaulHeader from "../assets/images/default_header.png";
export default {
  data() {
    return {
      options: [
        {
          search_menu: "1",
          label: "课程",
        },
        {
          search_menu: "2",
          label: "标准规范",
        },
      ],
      search_menu: "1",
      searchVal: "",
      myHead: {},
      myBusiness: {},
      personImgUrl: "",
      defaulHeader: defaulHeader, //默认显示头像路径
      typeName: "", //
      loginForm: {
        mobile: "",
        password: "",
      }, //登陆表单
      loginRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      }, //登陆表单验证
      maskShow: false,
      loginShow: false,
      registerShow: false,
      certInfo: {},
      resgisterForm: {
        name: "",
        //c_id: "",
        company:"",
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
      unitName: "", //单位名称
      state1: '',
      sousuo_con: '',

    };
  },
  mounted() {
    this.restaurants = this.loadAll();
    this.$store.dispatch("GET_GLOBAL_LOGIN", this.loginMask);
    this.getheadData();
    document.title = this.business ? this.business.name : "";

    console.log(window.location.href.includes("courplay"));
    // if (!window.location.href.includes("courplay")) {
    //   //如果当前页面不是courplay,就让下边自适应生效
    //   function bodyScale() {
    //     var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
    //     var scale = devicewidth / 1920; // 分母——设计稿的尺寸
    //     document.body.style.zoom = scale; //放大缩小相应倍数
    //   }
    //   bodyScale();
    // }else{
    //  let main = document.getElementById('main');
    //   console.log(main);
    //   main.style.width="100%"
    // }
    function bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      // document.body.style.zoom = scale; //放大缩小相应倍数
    }
    bodyScale();
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    checkMobile(b) {
      if (this.resgisterForm.mobile === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkCompany(b,br) {
      if (this.resgisterForm.company === "") {
        this.$refs[b].style.opacity = 1;
      }
      if (this.resgisterForm.company !== "") {
        this.$refs[b].style.opacity = 0;
      }
      if(this.resgisterForm.company.length>0&&this.resgisterForm.company.length<7){
        this.$refs[br].style.opacity=1;
      }
      if(this.resgisterForm.company.length>=7){
        this.$refs[br].style.opacity=0;
      }
      // console.log(this.resgisterForm.company.length);

    },
    sousuo(){
      if(this.$route.fullPath == '/'){
        this.$router.push(
            `/standard?search=${this.sousuo_con}`
        );
      }else{
        this.$router.push(
            `/standard?search=${this.sousuo_con}`
        );
        this.$router.go(0);
      }
     

    },
    // 注册表单验证
    checkName(b) {
      if (this.resgisterForm.name === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    closeMask() {
      this.maskShow = false;
      this.registerShow = false;
      for (let i in this.resgisterForm) {
        this.resgisterForm[i] = "";
      }
      this.unitName = "";
    },
    openMask() {
      this.maskShow = true;
      this.registerShow = true;
      // this.loginShow = false;
      this.axios.post("/login/company").then((res) => {
        this.unitList = res.data;
      });
    },
    updateInfo() {
      this.$router.push("/personal");
    },
    logOut() {
      //this.popShow=true;
      this.axios.post("/login/login_out").then((res) => {
        this.$router.push("/");
        localStorage.removeItem("eft");
        // localStorage.removeItem("employee_info");
        this.$store.dispatch("SET_EMPLOYEE_INFO", JSON.stringify({}));
        this.$router.go(0);
        // localStorage.removeItem("business_info");
      });
      // //this.popShow=true;
      // this.axios.post("/login/login_out").then((res) => {
      //   if (res.data.code == 0) {
      //     localStorage.removeItem("eft");
      //     localStorage.removeItem("employee_info");
      //     // this.$store.dispatch("SET_EMPLOYEE_INFO");
      //     // localStorage.removeItem("business_info");
      //     this.$router.push("/");
      //     this.$router.go(0);
      //   }
      // });
    },
    chengeSearchFlag(currentVal) {
      this.search_menu = currentVal;
    },
    globalSearch() {
      if (this.searchVal === "") {
        this.$message.error("请输入搜索内容!");
        return;
      }
      if (this.search_menu == 1) {
        this.$router.push(
          `/search?type=${this.search_menu}&search=${this.searchVal}`
        );
        if (this.$route.name === "Search") {
          this.$router.go(0);
        }
      }
      if (this.search_menu == 2) {
        this.$router.push(
          `/standard?type=${this.search_menu}&search=${this.searchVal}`
        );
        if (this.$route.name == "Standard") {
          this.$router.go(0);
        }
      }
      this.searchVal = "";
    },
    getheadData() {
      this.axios.post("/").then((res) => {
        this.myHead = res.data;
        this.myBusiness = res.data.business;
        this.$store.dispatch(
          "SET_EMPLOYEE_INFO",
          JSON.stringify(res.data.user_info)
        );
        if (res.data.is_has_cert == 0) {
          this.autoCert();
        }
      });
    },
    //  获取个人设置请求
    getPersonalSetData() {
      this.axios.post("index/my_settings").then((res) => {
        if (res.code == 0) {
          this.getPersonalSetData();
          this.getheadData();
        }
        console.log(res);
        this.realname = res.data.name;
        this.personImgUrl = res.data.head_img;
      });
    },
    // 登陆弹窗
    loginMask() {
      this.maskShow = true;
      this.loginShow = true;
    },
    closeLoginMask(formName) {
      this.maskShow = false;
      this.loginShow = false;
      this.$refs[formName].resetFields();
    },
    loginMethods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          let { mobile, password } = this.loginForm;
          fd.append("mobile", mobile);
          fd.append("password", password);
          this.axios.post("/login/login", fd).then((res) => {
            if (res.data.code === 0) {
              localStorage.setItem("eft", res.data.data.token);
              this.$message.success(res.data.msg);
              this.getheadData();
              this.getIndexData();
              // this.$router.push("/");
              this.$router.go(0);
              setTimeout(() => {
                this.maskShow = false;
                this.loginShow = false;
                this.$refs[formName].resetFields(); //清空表单
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    getIndexData() {
      this.axios.get("/").then((res) => {
        console.log(1);
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          );
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          );
        }
      });
    },
    autoCert() {
      this.axios.post("/exam/automatic_cert").then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          return;
        } else {
          this.certInfo = res.data.info;
          setTimeout(() => {
            this.createCert();
          }, 800);
        }
      });
    },
    createCert() {
      html2canvas(this.$refs.certificate).then((canvas) => {
        let base64 = canvas.toDataURL("image/png");
        let fd = new FormData();
        fd.append("pic", base64);
        this.axios.post("/exam/cert_pic", fd).then((res) => {});
      });
    },
    loadAll() {
      return [
        { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
        { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
        { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
        { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
        { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
        { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
        { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
        { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
        { "value": "星移浓缩咖啡", "address": "上海市嘉定区新郁路817号" },
        { "value": "阿姨奶茶/豪大大", "address": "嘉定区曹安路1611号" },
        { "value": "新麦甜四季甜品炸鸡", "address": "嘉定区曹安公路2383弄55号" },
        { "value": "Monica摩托主题咖啡店", "address": "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F" },
        { "value": "浮生若茶（凌空soho店）", "address": "上海长宁区金钟路968号9号楼地下一层" },
        { "value": "NONO JUICE  鲜榨果汁", "address": "上海市长宁区天山西路119号" },
        { "value": "CoCo都可(北新泾店）", "address": "上海市长宁区仙霞西路" },
        { "value": "快乐柠檬（神州智慧店）", "address": "上海市长宁区天山西路567号1层R117号店铺" },
        { "value": "Merci Paul cafe", "address": "上海市普陀区光复西路丹巴路28弄6号楼819" },
        { "value": "猫山王（西郊百联店）", "address": "上海市长宁区仙霞西路88号第一层G05-F01-1-306" },
        { "value": "枪会山", "address": "上海市普陀区棕榈路" },
        { "value": "纵食", "address": "元丰天山花园(东门) 双流路267号" },
        { "value": "钱记", "address": "上海市长宁区天山西路" },
        { "value": "壹杯加", "address": "上海市长宁区通协路" },
        { "value": "唦哇嘀咖", "address": "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元" },
        { "value": "爱茜茜里(西郊百联)", "address": "长宁区仙霞西路88号1305室" },
        { "value": "爱茜茜里(近铁广场)", "address": "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺" },
        { "value": "鲜果榨汁（金沙江路和美广店）", "address": "普陀区金沙江路2239号金沙和美广场B1-10-6" },
        { "value": "开心丽果（缤谷店）", "address": "上海市长宁区威宁路天山路341号" },
        { "value": "超级鸡车（丰庄路店）", "address": "上海市嘉定区丰庄路240号" },
        { "value": "妙生活果园（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "香宜度麻辣香锅", "address": "长宁区淞虹路148号" },
        { "value": "凡仔汉堡（老真北路店）", "address": "上海市普陀区老真北路160号" },
        { "value": "港式小铺", "address": "上海市长宁区金钟路968号15楼15-105室" },
        { "value": "蜀香源麻辣香锅（剑河路店）", "address": "剑河路443-1" },
        { "value": "北京饺子馆", "address": "长宁区北新泾街道天山西路490-1号" },
        { "value": "饭典*新简餐（凌空SOHO店）", "address": "上海市长宁区金钟路968号9号楼地下一层9-83室" },
        { "value": "焦耳·川式快餐（金钟路店）", "address": "上海市金钟路633号地下一层甲部" },
        { "value": "动力鸡车", "address": "长宁区仙霞西路299弄3号101B" },
        { "value": "浏阳蒸菜", "address": "天山西路430号" },
        { "value": "四海游龙（天山西路店）", "address": "上海市长宁区天山西路" },
        { "value": "樱花食堂（凌空店）", "address": "上海市长宁区金钟路968号15楼15-105室" },
        { "value": "壹分米客家传统调制米粉(天山店)", "address": "天山西路428号" },
        { "value": "福荣祥烧腊（平溪路店）", "address": "上海市长宁区协和路福泉路255弄57-73号" },
        { "value": "速记黄焖鸡米饭", "address": "上海市长宁区北新泾街道金钟路180号1层01号摊位" },
        { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
        { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
        { "value": "阳阳麻辣烫", "address": "天山西路389号" },
        { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
      ];
    },
    handleSelect(item) {
      console.log(item);
    }
  },
  computed: {
    userInfo() {
      if (JSON.stringify(this.$store.state.empInfo) !== "{}") {
        return JSON.parse(this.$store.state.empInfo);
      }
    },
    business() {
      if (JSON.stringify(this.$store.state.business) !== "{}") {
        return JSON.parse(this.$store.state.business);
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.search {
  .el-input,
  .search_in .el-input__inner {
    border-color: #dcdfe6 !important;
    border-radius: 0%;
    height: 40px;
  }
  .search_menu {
    .el-input .el-input__inner {
      border-color: #dcdfe6 !important;
      text-align: center;
      border-radius: 0;
      border-right: 0px;
    }
  }
}

@media screen and (max-width: 1378px) {
  .el-dropdown-menu {
    position: absolute;
    left: 1450px !important;
    top: 200px;
    // background: pink;
  }
}
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .el-dropdown-menu {
    position: absolute;
    left: 1530px !important;
    top: 200px;
    // background: green;
  }
}
</style>
<style lang="scss" scoped>
// header中去除eslint下划线
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
//150%
@media screen and (max-width: 1378px) {
  .header {
    width: 100%;
    height: 80px;
    background: #FFFFFF;

    .left {
      width: 24%;
      height: 52px;
      display: flex;
      margin-left: 13%;
      .searchto {
        padding: 17px 0px 0px 130px;
        position: relative;
        display: inline-block;
      }
      .search-btn {
        position: absolute;
        top: 51%;
        left: 90%;
      }
      .login-css {
        margin-left: 243px;
        margin-top: 29px
      }
      .logo-css {
        margin-right: 60px;
      }
      .logo {
        // width: 60px;
        height: 50px;
        margin-top: 15px;
        margin-left: 2%;
      }
      .logo_title {
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 80px;
        text-align: left;
        padding-left: 5px;
        font-size: 18px;
        font-weight: bold;
        margin-left: 3%;
      }
    }

    .middle {
      width: 675px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 38%;
      margin-top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // background: #8bb9ff;
      .search {
        // width: 364px;
        width: 230px;
        height: 35px;
        display: flex;
        margin-left: -10px;

        .search_menu {
          width: 235px; //128
          height: 20px;
          padding: auto;
        }
        .search_but {
          width: 130px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          //background: #8bb9ff;
          .right {
            width: 120px;
            height: 45px;
            position: absolute;
            left: 106%;
            top: -4px;
            display: flex;
            padding-top: -100px;
            color: #000;
            .please {
              margin-top: 12px;
              margin-left: 10px;
              cursor: pointer;
            }
            & > img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
            .header_name {
              //width: 100%;
              margin-left: 10px;
            }
            .el-icon--right {
              position: absolute;
              left: 50%;
              top: 187x;
            }
          }

          .search_but_text {
            margin-top: 8px;
            margin-left: 17px;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
// 125%
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .header {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    .left {
      width: 450px;
      height: 52px;
      display: flex;
      margin-left: 13%;
      .searchto {
        padding: 17px 0px 0px 130px;
        position: relative;
        display: inline-block;
      }
      .search-btn {
        position: absolute;
        top: 52%;
        left: 90%;
      }
      .login-css {
        margin-left: 228px;
        margin-top: 30px
      }
      .logo-css {
        margin-right: 57px;
      }

      .logo {
        // width: 60px;
        height: 50px;
        margin-top: 15px;
        margin-left: 2%;
      }
      .logo_title {
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 80px;
        text-align: left;
        margin-top: 14px;
        font-size: 18px;
        font-weight: bold;
        margin-left: 3%;
        padding-left: 10px;
        //background: olivedrab;
      }
    }
    .middle {
      width: 780px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 36%;
      margin-top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      //background: #8bb9ff;
      .search {
        // width: 364px;
        width: 200px;
        height: 35px;
        display: flex;
        margin-left: 45px;

        .search_menu {
          width: 235px; //128
          height: 20px;
          padding: auto;
          text-align: center;
          margin-left: -20%;
        }
        .search_but {
          width: 130px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          opacity: 1;

          // background: #8bb9ff;
          .right {
            width: 120px;
            height: 45px;
            position: absolute;
            left: 106%;
            top: -4px;
            display: flex;
            padding-top: -100px;
            color: #000;
            .please {
              margin-top: 12px;
              margin-left: 10px;
              cursor: pointer;
            }
            & > img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
            .header_name {
              //width: 100%;
              margin-left: 10px;
            }
            .el-icon--right {
              position: absolute;
              left: 50%;
              top: 187x;
            }
          }
          .search_but_text {
            margin-top: 8px;
            margin-left: 17px;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
}
// 100%
@media screen and (min-width: 1555px) {
  .header {
    width: 100%;
    height: 97px;
    background: #FFFFFF;
    .left {
      width: 100%;
      height: 52px;
      display: flex;
      margin-left: 374px;
      .searchto {
        padding: 27px 0px 0px 130px;
        position: relative;
        display: inline-block;
      }
      .search-btn {
        position: absolute;
        top: 70%;
        left: 90%;
      }
      .login-css {
        margin-left: 222px;
        margin-top: 38px
      }
      .logo-css {
        margin-right: 37px;
      }
      .logo {
        height: 50px;
        margin-top: 22px;
        margin-left: 2%;
      }
      .logo_title {
        width: auto;
        height: 100%;
        line-height: 96px;
        text-align: left;
        margin-top: 22px;
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .middle {
      width: 750px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 41%;
      margin-top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // background: #8bb9ff;
      .search {
        // width: 364px;
        width: 230px;
        height: 35px;
        display: flex;
        margin-left: 45px;
        // background: green;
        .search_menu {
          width: 235px; //128
          height: 20px;
          padding: auto;
          text-align: center;
        }
        .search_but {
          width: 130px;
          height: 40px;
          font-size: 16px;
          color: #ffffff;
          opacity: 1;

          //background: #8bb9ff;
          .right {
            // margin-left: -10px;
            width: 142px;
            height: 45px;
            position: absolute;
            left: 106%;
            top: -3px;
            display: flex;
            padding-top: -100px;
            color: #000;
            //background: greenyellow;
            .please {
              margin-top: 5px;
              margin-left: 10px;
              cursor: pointer;
            }
            & > img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
            }
            .header_name {
              margin-left: 10px;
            }
            .el-icon--right {
              position: absolute;
              left: 50%;
              top: 187x;
            }
          }

          .search_but_text {
            margin-top: 8px;
            margin-left: 17px;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.39);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .register {
    max-width: 680px;
    max-height: 647px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1% 8%;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 46px;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    form {
      width: 350px;
      max-height: 500px;
      margin: 10% auto;
      label {
        display: block;
        width: 350px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 20px;
        padding: 6px 5px;
        box-sizing: border-box;
        margin-bottom: 24px;
        background: url("../assets/images/sprite.png") no-repeat;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 50%;
          left: -6%;
          transform: translateY(-50%);
        }
        input {
          width: 80%;
          height: 75%;
          border: 0;
          outline: 0;
          font-size: 16px;
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
          }
        }

        b {
          font-weight: normal;
          color: #f56c6c;
          font-size: 12px;
          position: absolute;
          left: 0;
          bottom: -50%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      button.now-register {
        width: 239px;
        height: 40px;
        background: #8bb9fc;
        border-radius: 20px;
        font-size: 15px;
        color: #ffffff;
        border: 0;
        margin-top: 12px;
        cursor: pointer;
      }
      .label-name-icon {
        background-position: 4% -3%;
      }
      .label-unit-icon {
        background-position: 4% 18%;
      }
      .label-phone-icon {
        background-position: 4% 39%;
      }
      .label-code-icon {
        position: relative;
        background-position: 4% 61%;
        button.send-code-btn {
          height: 100%;
          border: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 50px;
          color: #000;
          cursor: pointer;
        }
        .send-disable {
          cursor: not-allowed !important;

          background-color: #e7e8e9;
        }
      }
      .label-password-icon {
        background-position: 4% 82%;
      }
    }
  }
  .login-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .forget-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    .msg-box {
      display: flex;
      button {
        width: 40%;
        border: 0;
        background-color: rgba($color: #8bb9fc, $alpha: 0.8);
        border-radius: 50px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: rgba($color: #8bb9fc, $alpha: 0.6);
        }
      }
    }
  }
}
.c-b {
  width: 0;
  height: 0;
  overflow: hidden;
  .certificate {
    width: 1080px;
    height: 1524px;
    background: url("../assets/images/cert.jpg") no-repeat;
    background-size: cover;
    position: relative;
    top: 100%;
    left: 0;
    // z-index: -99;
    span {
      font-size: 38px;
      // font-weight: 400;
      font-family: "楷体";
      position: absolute;
      color: #3a3a3a;
    }
    .cert-name {
      top: 37.1%;
      left: 19%;
    }
    .cert-stime {
      top: 37.1%;
      left: 42.5%;
      // font-size: 32px;
    }
    .cert-etime {
      top: 37.1%;
      left: 70%;
      // font-size: 32px;
    }
    .cert-course-time {
      top: 45.2%;
      right: 30%;
    }
    .cert-identifier {
      bottom: 22.3%;
      left: 30.5%;
      font-size: 26px;
    }
    .cert-ftime {
      left: 30.5%;
      bottom: 17.7%;
      font-size: 26px;
    }
    .cert-cname {
      right: 13%;
      bottom: 12%;
    }
  }
}
.input::placeholder{
  transform: translateX(50px);
  color: #aeb5c1;
}
.input:focus{
  outline: none;
}
</style>
